/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import company_employee from "../images/pdf/forms/company/Employee_Verification_Form.pdf"
import company_checklist from "../images/pdf/forms/company/Checklist.pdf"
import company_reference from "../images/pdf/forms/company/Reference_Form.pdf"
import company_transcript from "../images/pdf/forms/company/Transcript_Form.pdf"
import company_release from "../images/pdf/forms/company/Release_Form.pdf"
import dealer_employee from "../images/pdf/forms/dealer/Employee_Verification_Form.pdf"
import dealer_checklist from "../images/pdf/forms/dealer/Checklist.pdf"
import dealer_reference from "../images/pdf/forms/dealer/Reference_Form.pdf"
import dealer_transcript from "../images/pdf/forms/dealer/Transcript_Form.pdf"
import dealer_release from "../images/pdf/forms/dealer/Release_Form.pdf"

import Header from "./header"
import "./layout.css"

const Layout = ({type, children }) => { 
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  
  const notOpen = () => alert('The application deadline has passed, please check back in mid-January 2024')

  return (
    <div className="layout">
      <Header siteTitle={data.site.siteMetadata.title} onMenuClick={() => setIsMenuOpen(!isMenuOpen)} />
      <div>
        <div className={isMenuOpen ? 'menu menu--active' : 'menu'}>
          {type === 'company' ? (
            <>
            <a className="application" target="_blank" href="https://apply.brunswickscholarships.com/application/login">Application</a>
              {/* <a className="application" href="" onClick={notOpen}>Application</a> */}
              <a target="_blank" href={company_checklist}>Check List</a>
              <a target="_blank" href={company_transcript}>Transcript Form</a>
              <a target="_blank" href={company_employee}>Employee Verification Form</a>
              <a target="_blank" href={company_reference}>Reference Form</a>
              <a target="_blank" href={company_release}>Release Form</a>
            </>
          ) : (
            <>
              <a className="application" target="_blank" href="https://apply-dealer.brunswickscholarships.com/application/login">Application</a>
              {/* <a className="application" href="" onClick={notOpen}>Application</a> */}
              <a target="_blank" href={dealer_checklist}>Check List</a>
              <a target="_blank" href={dealer_transcript}>Transcript Form</a>
              <a target="_blank" href={dealer_employee}>Employee Verification Form</a>
              <a target="_blank" href={dealer_reference}>Reference Form</a>
              <a target="_blank" href={dealer_release}>Release Form</a>
            </>
          )}
        </div>
        <main className="main">{children}</main>
        <footer className="footer">
          <Link to={'/terms'}>Terms of Use</Link>
          <Link to={'/privacy'}>Privacy Policy</Link>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
